<template>
  <q-dialog v-model="show" persistent maximized>
        <q-card class="bg-grey-6 relative">
          <q-card-section class="full-height">
            <q-card class="form absolute-center ">
              <q-card-section class="text-center text-bold bg-yellow-7">
                Login Admin
              </q-card-section>
              <q-card-section class="">

                <q-input outlined v-model="user.username" id="id" placeholder="username" @keyup.enter="$refs.pass.focus()" dense>
                  <template v-slot:append>
                    <q-icon name="account_circle" @click="text = ''" class="cursor-pointer" />
                  </template>
                </q-input>
                <q-input outlined v-model="user.password" ref="pass" :type="isPwd ? 'password' : 'text'" id="password" placeholder="Password"  @keyup.enter="login()" dense>
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd = !isPwd"
                    />
                  </template>
                </q-input>
              </q-card-section>
              <q-card-actions  align="center" class="q-py-lg">
                <q-btn outline  style="width: 120px;" color="light-blue-6" label="Batal" @click="$router.push('/')" no-caps/>
                <q-btn style="width: 120px;color:white;" color="light-blue-6" label="Masuk" no-caps @click="login()"/>
              </q-card-actions>
            </q-card>
          </q-card-section>
        </q-card>
  </q-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import md5 from 'md5'
export default {
  name: "Login",
  data: function() {
    return {
      user: {},
      isPwd: true,
      show: true,
    };
  },
  methods: {
    ...mapActions(["getCurrentUser"]),
    login(){
      var usr = {}
      usr.username = this.user.username
      usr.password = md5(this.user.password)
      this.$http.post('/login', usr, {})
      .then (async result => {
        // localStorage.setItem('admin-smp-afbs', result.data)
        localStorage.setItem('smp-afbs', result.data)
        await this.getCurrentUser();

        await this.$router.push('/admin')

      })
      .catch(err => {
        this.$q.notify({
          icon: "warning",
          color: "negative",
          message: "Username atau password salah",
        });
        
      })
    },
  },
  computed: mapState(["currentUser"]),
};


</script>
<style lang="scss" scoped>

.form{
  width: 300px; 
}
label{
  padding-top: 10px; padding-bottom: 15px;
  color: #505050; font-weight: bold;
}
</style>
